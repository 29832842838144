.input {
  box-sizing: border-box;
  width: 100%;
  padding: 7px 10px;
  margin-right: 10px;

  &.--error {
    border-color : red;
    outline: red;
  }
}
@import "../vars";

.btn {
  padding : 5px;
  cursor : pointer;
  border : 1px solid $borderColor;
  font-weight: bold;

  &.--danger {
    background-color : $danger-color;
    color : white;
  }

  &.--success {
    background-color : $success-color;
    color : white;
  }

  &[disabled] {
    cursor : not-allowed;
    color : #474141;
    background-color: #999999;
  }

  &:focus {
    outline : none;
  }

  &:active {
    box-shadow: 5px 5px 10px inset rgba(0,0,0,.3);
  }

}
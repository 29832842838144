@import "vars";

* {
  margin: 0;
  padding: 0;
}

html {
  font-family: Sans-serif;
}

body {
  background-color : $body-bg-color;
}

.container {
  max-width : 800px;
  margin : 0 auto;
  padding : $defaultIndent + px;
  background-color: $container-bg-color;
  border-radius: 5px;
}




